<template>
	<div class="form-header" :class="[$responsive, classObject]" :style="styleObject">
		<div class="form-header__content">
			<span>
				<slot></slot>
			</span>	
		</div>
	</div>
</template>

<script>
	export default {
		name: "FormHeader",
		props: {
			image: { type: String }
		},
		data() {
			return { headerOffsetWidth: 0 }
		},
		computed: {
			styleObject() {
				return !this.image ? {} 
					: { backgroundImage: `url(${require('@/assets/' + this.image)})` } 
			},
			classObject() {
				return this.headerOffsetWidth < 600 ? 'form-header--narrow' : null;
			}
		},
		mounted() {
			this.headerOffsetWidth = this.$el.offsetWidth;
		}
	}
</script>

<style scoped>

.form-header {
	--max-text-size: 2.75rem;
	--text-size: clamp(1.66rem, 5vw, var(--max-text-size));
	--content-height: calc(var(--text-size) * 3.5);
}

.form-header--narrow {
	--max-text-size: 2.33rem;
}

.form-header {
	background: var(--primary-color);
	background: var(--primary-gradient);
	/*background-image: url('~@/assets/zionesport-wallpaper-app-std-v2-small.png');*/
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: calc(100% / (1.6 * 1.618) - var(--content-height) / 2);
	padding-bottom: calc(100% * .0476);
}

.form-header__content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: var(--content-height);
	width: 60%;
	padding-left: 5%;
}

.mobile .form-header__content {
	width: clamp(60%, 70vw, 80%);
}

.form-header__content > span {
	color: #ffffff;
	font-size: var(--text-size);
	letter-spacing: -0.066rem;
	line-height: 1.125em;
	text-shadow: 0px 0 8px rgba(0, 0, 0, 0.10);
	text-align: left;
	font-weight: 600;
}

</style>