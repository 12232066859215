<template>
	<div class="contact-form-component">
		<form-header imagex="zionesport-wallpaper-app-std-v2-small.png">
			¿Listo para llevar tu liga al siguiente nivel?
		</form-header>
		<div class="contact-form-component__body">
			<h2 class="title" v-markdown:title>
				Contacto con *ventas*
			</h2>
			<p class="subtitle">
				Compártenos tu información y nos pondremos en contacto contigo lo antes posible.
			</p>
			<form class="contact-form" method="post" action="#" @submit.prevent="submitForm">
				<div v-for="(field, key, index) in contact" :key="key">
					<form-field :index="index" :field="field" v-model.trim="field.value" />	
				</div>
				<base-button class="contact-form__submit">
					Enviar Información
				</base-button>
				<re-captcha ref="recaptcha" @verify="doSubmit"></re-captcha>
			</form>
		</div>
	</div>
	<modal-processing v-if="isSending">
		Enviando tu información..
	</modal-processing>
</template>

<script>
const axios = require('axios');
import reCaptcha from "@/components/ReCaptchaV2.vue";
import modalProcessing from "@/components/ModalProcessing.vue";
import baseButton from "@/components/BaseButton.vue";
import formField from "@/components/FormField.vue";
import useForm from "@/forms.js";
import formHeader from "@/components/FormHeader.vue";

export default {
	name: "ContactForm",
	components: {
		reCaptcha,
		modalProcessing,
		baseButton,
		formField,
		formHeader
	},
	emits: ['sent'], // new on Vue3
	setup() {
		const { checkForm } = useForm();
		return { checkForm };
	},
	data() {
		return {
			contact: {
				name: {
					value: "",
					type: "text",
					label: "Nombre",
					required: true,
					errorMessage: []
				},
				email: {
					value: "",
					type: "email",
					label: "Correo electrónico",
					required: true,
					errorMessage: []
				},
				phone: {
					value: "",
					type: "text",
					label: "Teléfono",
					required: true,
					errorMessage: []
				},
				company: {
					value: "",
					type: "text",
					label: "Liga o Institución deportiva",
					required: true,
					errorMessage: [],
					helpText: "Añade el nombre de tu Liga ó Institución deportiva"
				},
				contactPreference: {
					value: "Cualquiera", // set initial value
					type: "select",
					label: "Medio de contacto",
					values: ['Cualquiera', 'Preferentemente Whatsapp', 'Preferentemente Teléfono'],
					required: false,
					errorMessage: [],
					helpText: "¿Cómo prefieres que te contactemos?"
				},
			},
			sent: false,
			isSending: false
		}
	},
	computed: {
		contactData() {
			return Object.entries(this.contact).reduce((acc, el) => ({...acc, [el[0]]: el[1].value }), {})
		},
		isDesktop() {
			return (!this.$responsive.mobile && !this.$responsive.tablet);
		}
	},
	methods: {
		doSubmit(token) {
			this.isSending = true;
			axios.post('/api/contact', { contact: this.contactData, recaptchaToken: token })
				.then(response => {
					console.log(response);
					this.sent = true;
				})
				.catch(error => console.log(error))
				.finally(() => {
					this.isSending = false;
					this.$emit('sent', this.contactData.name)
				})
		},
		submitForm() {
			if (this.checkForm(this.contact)) {
				this.$refs.recaptcha.execute();
			}
		}
	}
}
</script>

<style scoped>
.contact-form-component {
	background-color: #ffffff;
	border-radius: 1rem;
	overflow: hidden;
}

.contact-form-component__body {
	padding: 1rem 1.75rem;
}

.contact-form {
	font-family: 'open sans', helvetica, sans-serif;
}

.contact-form__submit {
	margin: 2.5rem auto 2rem;	
}
</style>