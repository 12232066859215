<template>
	<div class="contact-us">
		<div class="page-section" :class="$responsive">
			<div class="contact-section">
				<div class="grid-image">
					<img class="contact__image" src="../assets/img-c0-sec-04-v2-a.png">
				</div>
				<div class="grid-clients">
					<our-clients-compact :grayscale="true"></our-clients-compact>
				</div>
				<div class="grid-form">
					<contact-form @sent="sentDone"></contact-form>
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
import contactForm from "@/components/ContactForm.vue";
import ourClientsCompact from "@/components/OurClientsCompact.vue";

export default {
	name: "ContactUs",
	components: {
		contactForm,
		ourClientsCompact
	},
	data() {
		return {
			sent: false
		}
	},
	methods: {
		sentDone(contactName) {
			this.sent = true;
			let firstName = contactName.split(" ")[0];
			this.$router.push({ name: 'thankYou', query: { name: firstName }});
		}
	}
}
</script>

<style scoped>

.page-section.mobile {
	padding: 1rem; /* customized for contact form */
}

.contact-section {
	display: grid;
	grid-template-areas: "image form" "clients form";
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
}

.sm-view .contact-section {
	grid-template-areas: "image" "form" "clients";
	grid-template-columns: 1fr;
	grid-template-rows: auto;
}

.tablet .contact-section {
	width: 75%;
	margin: 0 auto;
}

.grid-image {
	grid-area: image;
}

.grid-clients {
	grid-area: clients;
	padding-top: 1rem;
}

.grid-form {
	grid-area: form;
}

.contact__image {
	display: block;
	width: 90%;
	margin: 0 auto 0 0;
}

.mobile .contact__image {
	display: none;
	width: 80%;
	margin: 0 auto;
}

.tablet .contact__image {
	display: none;
	width: 60%;
	margin: 0 auto;
}

.sm-view .grid-clients {
	padding-top: 3.75rem;
}
</style>